body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.showOnMobile {
  display: none !important;
}
.hideOnMobile {}

.kooWid-head > h2 {
  margin: 0 auto;
  font-size: 150%;
}

#__koo-trending-embed {
  display: inline-flex;
}

.h7r {
  height:7rem;
}

.loading {
  text-align: center;
}
.loading img {
  margin: 0 auto;
}
.kooWid-wrap{float:left;width:100%;background-color:#fff;border-radius:5px;margin-top:15px;box-shadow:0 0 5px rgba(0,0,0,.1)}
.kooWid-wrap *{box-sizing:border-box}
.kooWid-head{width:100%;min-height:25px;position:relative;border-radius:5px;background-color:#f1f1f1;display:flex;align-items:center;padding:5px 10px}
.kooWid-heading{font-size:15px;font-weight:900;color:#000;text-transform:uppercase}
.kooWid-icon{width:45px;height:45px;display:flex;align-items:center;justify-content:center;border-radius:50%;background-color:#fff;border:3px solid #f1f1f1;position:absolute;top:-15px;right:7px;padding:3px;box-shadow:0 -2px 5px rgba(0,0,0,.1)}
.kooWid-icon svg{width:auto;height:100%;display:block}
.cls-1{fill:#facd00}
.cls-2{fill:#383838}
.kooWid-body{width:100%;position:relative;padding:10px 0}
.kooWid-list{width:100%;height:210px;position:relative;overflow:hidden;overflow-y:auto;padding:0 10px}
.kooWid-item{display:flex;align-items:center;border-top:solid 1px #dadada;padding-top:10px;margin-top:10px}
.kooWid-item:first-child{border-top:none;padding-top:0;margin-top:0}
.kooWid-thumb{width:70px;flex:0 0 auto;margin:0 10px 0 0;line-height:0;border-radius:3px}
.kooWid-thumb img{max-width:100%}
.kooWid-listhead{font-size:13px;font-weight:500;float:left;margin-bottom:5px;width:100%;color:#777;padding-right:60px;position:relative}
.kooWid-listhead strong{font-weight:700;display:block;font-size:13px;line-height:19px}
.kooWid-listpara{font-size:13px;line-height:16px;font-weight:400;word-break:break-word}
.kooWid-list::-webkit-scrollbar{width:5px}
.kooWid-list::-webkit-scrollbar-track{background-color:#f1f1f1}
.kooWid-list::-webkit-scrollbar-thumb{background-color:#dadada;border-radius:6px}
.kooWid-listDt{display:inline-block;float:right;color:#777;font-size:12px;font-weight:500;line-height:15px;position:absolute;right:0;top:5px;text-align:right}
.kooWid-listStr{float:left}
.kooWid-listLnk{float:left;width:100%}
.kooWid-listLnk img{width:15px;margin-left:5px;position:relative;top:2px}
.kooWid-handl{float:left;margin-bottom:7px;width:100%}
.kooWid-handl-tx{color:#666;float:left;font-size:13px;font-weight:400;line-height:18px}
.kooWid-thumbLnk{float:left}
img {
  object-fit: cover;
  height: 100%;
}
@media only screen and (max-width: 1200px) {
  #headr {
    height: auto !important;
  }
}
a { cursor: pointer; }

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide123 img23 {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature .swiper-wrapper .feature-box{
  height: 500px;
}
.feature .swiper-wrapper .feature-box img {
  margin-top: 130px;
}
.feature .swiper-wrapper .feature-box p {
  padding: 5px;
}
.slides1 {
  display: none;
}
.feature-main h3 {
  line-height: 40px;
}
.global-politics .gpMain {
  display: block;
}
@media (max-width: 991px) {
  .showOnMobile {
    display: block !important;
  }
  .showOnMobile .toprightvideo {
    width: 100%;
  }
  .showOnMobile .addBanner{
    width: 100%;
    margin-top: 16px;
    margin-bottom: 34px;
  }

  .hideOnMobile {
    display: none !important;
  }
  #__koo-trending-embed {
    margin-bottom: 34px;
    width: 100%;
  }
  hr {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }
  .slides42 {
    display: none !important;
  }
  .slides12 {
    display: block !important;
  }
  .ablum-area .col-xl-4 {
    margin-top: 34px;
  }
}